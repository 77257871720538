import styled, { keyframes } from 'styled-components';

import leftImage from 'assets/children-holding-piles-of-books.png';
import arcoImage from 'assets/arco.png';

import { Col } from 'react-grid-system';

import { Button, Typography } from '@arcotech-services/eem-afrodite-ui';
import { Logo } from 'components';
import {
  useEffect, useContext,
} from 'react';
import { LogoProductContext } from 'Context';
import { EventoAmplitude } from 'amplitude/enums';
import { useLogEvent } from 'hooks';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { LoginForm } from './components';

const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  
  @media(max-width: 760px) {
    img {     
     margin-top: 20px;
    }

    #right-div{
      align-items: center;
    }
  }
  @media(max-width: 320px) {
    #right-div{
      width: 320px;
      align-items: baseline;
    }
    .rightContentDiv{
      padding: 0 10px;
    }
  }
  
`;
const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  justify-content: center;
  min-height: 100% !important;
  
  .js-loading {
    animation-play-state: paused !important;
  }
`;

const raise = keyframes`
  from {
    background-position: center 600px;
  }

  to {
    background-position: center 100%;
  }
`;

const raiseUntilTop = keyframes`
  from {
    background-position: center 600px;
  }

  to {
    background-position: center 40px;
  }
`;

const LeftImageDiv = styled.div`
  min-height: 100% !important;
  width: 100%;
  background-image: url(${arcoImage});
  background-repeat: no-repeat;
  position: absolute;
  animation: ${raise} 1.5s ease;
  animation-timing-function: ease-in;
  background-position: center 100%;
  text-align: center;
  
  @media(min-height: 0px) and (max-height: 670px) {
    animation: ${raiseUntilTop} 1.5s ease;
      
    background-position: center 40px;
  }
`;
const LeftImage = styled.div`

  position: absolute;
  background-image: url(${leftImage});
  background-position: center 100%;
  width: 100%;
  min-height: 100% !important;
  background-repeat: no-repeat;
  @media(min-height: 0px) and (max-height: 670px) {
    background-position: center 70px;
  }
`;

const RightDiv = styled.div`
  height: 100%;
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const RightContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 4rem;
  padding: 0 5px;
  min-width: 45%;
  max-width: 70%;

  @media(min-width: 501px) {
    max-width: 50%;
  }

  @media(min-width: 770px) {
    max-width: 59%;
  }

  @media(min-width: 1200px) {
    max-width: 45%;
  }
`;

const Footer = styled.div`
  color: #0052CC;
  text-align: center;
  padding: 25px 0px 25px 0px;
  width: 100%;
`;

type LoginPageType = {
  reLoad: boolean,
}

function LoginPage({ reLoad }: LoginPageType) {
  const logEvent = useLogEvent();
  const {
    logoUrl,
    productParceiro,
  } = useContext(LogoProductContext);
  const { Text } = Typography;

  function showPage() {
    setTimeout(() => {
      document.getElementById('left-div')?.classList.remove('js-loading');
    }, 1250);
  }
  window.addEventListener('load', showPage);

  useEffect(() => {
    logEvent(EventoAmplitude.VIU_TELA_LOGIN, { tela: 'login', parceiro: productParceiro });

    if (reLoad) {
      setTimeout(() => {
        document.getElementById('left-div')?.classList.remove('js-loading');
      }, 1250);
    }
  }, []);

  return (
    <MainDiv>
      {logoUrl ? (
        <Row id="Row">
          <Col xs={0} sm={0} md={5} lg={6} xl={6} xxl={6} style={{ backgroundColor: '#0F3670' }}>
            <LeftImageDiv id="left-div" className="js-loading">
              <LeftImage />
            </LeftImageDiv>
          </Col>

          <Col sm={12} md={7} lg={6} xl={6} xxl={6}>
            <RightDiv id="right-div">
              <RightContentDiv>
                <Logo src={logoUrl} />
                <LoginForm />
              </RightContentDiv>
              <Footer>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    style={{ margin: '16px 0', padding: '0', textDecoration: 'none' }}
                    kind="link"
                    onClick={() => { window.open('https://escolaemmovimento.com.br/politicaprivacidade/', '_blank'); }}
                  >
                    <Text type="default">
                      Termos de Uso e Política de Privacidade
                    </Text>
                  </Button>
                </div>
              </Footer>
            </RightDiv>
          </Col>
        </Row>
      ) : (
        <LoadingOverlay />
      )}
    </MainDiv>
  );
}

export default LoginPage;
