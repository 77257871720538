// eslint-disable-next-line no-shadow
export enum EventoAmplitude {
  CLICOU_ESQUECI_SENHA = 'clicou_esqueci_senha',
  PREENCHEU_LOGIN_SENHA_INVALIDOS = 'preencheu_login_senha_invalidos',
  PREENCHEU_LOGIN_USUARIO_INATIVO = 'preencheu_login_usuario_inativo',
  PREENCHEU_LOGIN_FORA_DO_HORARIO_PERMITIDO = 'preencheu_login_fora_do_horario_permitido',
  REALIZOU_LOGIN = 'realizou_login',
  CLICOU_ENTRAR = 'clicou_entrar',
  VISUALIZOU_TELA = 'visualizou_tela',
  VIU_TELA_LOGIN = 'viu_tela_login',
  ENVIOU_EMAIL = 'enviou_email',
  ENVIOU_SMS = 'enviou_sms',
  CLICOU_RECUPERAR_SENHA = 'clicou_recuperar_senha',
  CADASTROU_OUTRA_SENHA = 'cadastrou_outra_senha',
  CLICOU_VOLTAR_PARA_LOGIN = 'clicou_voltar_para_login',
  ESQUECEU_SENHA_CADASTRO_INATIVO = 'esqueceu_senha_cadastro_inativo',
  ESQUECEU_SENHA_AUTENTICACAO_EXTERNA = 'esqueceu_senha_autenticacao_externas',
  ESQUECEU_SENHA_SEM_CONTATO = 'esqueceu_senha_sem_contato',
}
