import React from 'react';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import styled from 'styled-components';

const MainDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

export function AfterLoginLoadingPage() {
  return (
    <MainDiv>
      <LoadingOverlay title="Carregando informações do ambiente" />
    </MainDiv>
  );
}
