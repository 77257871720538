import { logEvent, PropriedadesComuns } from 'amplitude/amplitude.helper';
import { useHistory } from 'react-router-dom';
import { EventoAmplitude } from 'amplitude/enums';

export default function useLogEvent() {
  const historico = useHistory();
  const payloadComum: PropriedadesComuns = {
    plataforma: 'Web',
    ambiente: window.location.hostname,
    tela: historico.location.pathname,
    parceiro: '',
    app: 'login',
  };
  return (
    evento: EventoAmplitude,
    payload: Object = {},
  ) => logEvent(evento, { ...payloadComum, ...payload });
}
