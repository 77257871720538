import { api } from './config';
import {
  AuthResponseData, CheckPinResponse, PromiseApiResponse,
} from './types';

export const authenticate = (username: string, password: string, context: string): PromiseApiResponse<AuthResponseData> => api.post('/login', {
  username, password, context,
},
{ headers: { 'Content-Type': 'application/json' } });

export const requestNewPassword = (user: string, urlChangePassword: string): PromiseApiResponse<any> => api.post('/users/new-password', {
  userInfo: user, urlChangePassword,
},
{ headers: { 'Content-Type': 'application/json' } });

export const checkPin = (pin: string): PromiseApiResponse<CheckPinResponse> => api.post('/users/check-pin',
  pin,
  { headers: { 'Content-Type': 'application/json' } });

export const resetPassword = (pin: string, newPassword: string): PromiseApiResponse<any> => api.put('/users/change-password', {
  pin, newPassword,
},
{ headers: { 'Content-Type': 'application/json' } });

export const getInfoContext = (context: string): PromiseApiResponse<any> => api.get('/login', {
  context,
},
{ headers: { 'Content-Type': 'application/json' } });
