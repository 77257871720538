import styled from 'styled-components';
import line from 'assets/line.png';
import { useHistory, useParams } from 'react-router-dom';
import { requestNewPassword } from 'services/api';
import {
  Input, Button, Typography, Toast,
} from '@arcotech-services/eem-afrodite-ui';
import { useContext, useState } from 'react';
import {
  ArrowDiv, Logo, WarnPage,
} from 'components';
import Validation from 'services/validations';
import { LogoProductContext } from 'Context';
import { useLogEvent } from 'hooks';
import { EventoAmplitude } from 'amplitude/enums';
import { isEmail } from 'utils/utils';

const MainDiv = styled.div`
  width: 97%;
  padding: 5px 5px;
  text-align: left;
`;

const FieldDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Line = styled.img`
  width: 97%;
`;
interface MatchParams {
  mode: string;
}

export enum RetornoForgotPasswordAPI {
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_SMS = 'SEND_SMS',
  CONTACT_ADMIN = 'CONTACT_ADMIN',
  INACTIVE_USER = 'INACTIVE_USER',
  EXTERNAL_AUTHENTICATION = 'EXTERNAL_AUTHENTICATION',
  INVALID = 'INVALID',
  INVALID_TOKEN = 'INVALID_TOKEN',
  OLD_PASSWORD = 'OLD_PASSWORD',
  OK = 'OK',
}

function ForgotPasswordForm() {
  const logEvent = useLogEvent();
  const { logoUrl, productParceiro, context } = useContext(LogoProductContext);
  const params = useParams<MatchParams>();
  const [user, setUser] = useState('');
  const [requested, setRequested] = useState(false);
  const [errorUsernameNull, setErrorUsernameNull] = useState(false);
  const [loginFailedInactivatedUser, setloginFailedInactivatedUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { Title, Text } = Typography;

  const handleRecuperarSenha = async () => {
    setLoading(true);
    if (!user) { setErrorUsernameNull(true); setRequested(true); setLoading(false); return; }

    logEvent(
      EventoAmplitude.CLICOU_RECUPERAR_SENHA,
      {
        tela: 'Esqueceu a senha',
        parceiro: productParceiro,
        username: isEmail(user),
      },
    );

    const urlChangePassword = context;
    const result = await requestNewPassword(user, urlChangePassword);
    const { data } = result;

    if (result.ok) {
      if (data.result === RetornoForgotPasswordAPI.SEND_SMS) {
        logEvent(
          EventoAmplitude.ENVIOU_SMS,
          {
            parceiro: productParceiro,
            username: isEmail(user),
            'categoria de perfil': data.categoriaPerfil.toString(),
          },
        );
        history.push({
          pathname: '/forgot/verifySms',
        });
      } else {
        logEvent(
          EventoAmplitude.ENVIOU_EMAIL,
          {
            parceiro: productParceiro,
            username: isEmail(user),
            'categoria de perfil': data.categoriaPerfil.toString(),
          },
        );
        history.push({
          pathname: '/forgot/verifyEmail',
        });
      }
    } else if (data === RetornoForgotPasswordAPI.CONTACT_ADMIN) {
      logEvent(
        EventoAmplitude.ESQUECEU_SENHA_SEM_CONTATO,
        {
          parceiro: productParceiro,
          username: isEmail(user),
        },
      );
      history.push({
        pathname: '/forgot/contactAdmin',
      });
    } else if (data === RetornoForgotPasswordAPI.EXTERNAL_AUTHENTICATION) {
      logEvent(
        EventoAmplitude.ESQUECEU_SENHA_AUTENTICACAO_EXTERNA,
        {
          parceiro: productParceiro,
          username: isEmail(user),
        },
      );
      history.push({
        pathname: '/forgot/authExternal',
      });
    } else if (data === RetornoForgotPasswordAPI.INACTIVE_USER) {
      logEvent(
        EventoAmplitude.ESQUECEU_SENHA_CADASTRO_INATIVO,
        {
          parceiro: productParceiro,
          username: isEmail(user),
        },
      );
      setloginFailedInactivatedUser(true);
    } else {
      // Não revelar pro usuário outros erros
      history.push({
        pathname: '/forgot/verifyEmail',
      });
    }

    setLoading(false);
  };
  const onChangeUser = (value: string) => {
    const validation = new Validation();
    setUser(value);
    setErrorUsernameNull(validation.username(value));
  };

  if (params.mode === 'verifyEmail') {
    return (
      <WarnPage
        title="Verifique seu e-mail"
        text="
          Se você tiver uma conta, enviaremos um link de redefinição para o e-mail cadastrado na plataforma.
          Se não estiver na sua caixa de entrada, verifique a caixa de spam."
        showButton
        showBottom
      />
    );
  }
  if (params.mode === 'verifySms') {
    return (
      <WarnPage
        title="Enviamos um SMS"
        text="
          Se você tiver uma conta, enviaremos um link de redefinição para o número de celular cadastrado na plataforma."
        showButton
        showBottom
      />
    );
  }
  if (params.mode === 'authExternal') {
    return (
      <WarnPage
        title="Não foi possível criar uma nova senha"
        text="
          Sua autenticação é feita por um sistema externo, consulte o administrador para obter as intruções."
        showButton={false}
        showBottom
      />
    );
  }
  if (params.mode === 'contactAdmin') {
    return (
      <WarnPage
        title="Não foi possível criar uma nova senha"
        text="
          Por favor entre em contato com a escola para redefinir sua senha e cadastrar um novo e-mail de contato."
        showButton={false}
        showBottom
      />
    );
  }

  return (
    <>
      <ArrowDiv />
      <Logo src={logoUrl} />
      <MainDiv>
        <Title level={2} style={{ color: '#333333', marginBottom: '10px' }}>
          {params.mode === 'expired' && 'Seu link de login expirou'}
          {params.mode === 'expired' || 'Esqueceu a senha?'}
        </Title>
        {(loginFailedInactivatedUser && (!errorUsernameNull)) && <Toast kind="info" title="Cadastro inativo. Entre em contato com a escola." closeButton={false} style={{ width: '87%', marginBottom: '10px' }} />}
        <Text style={{ color: '#515151' }}>
          {params.mode === 'expired' && 'Digite abaixo o e-mail ou nome de usuário associado à sua conta para receber um novo link.'}
          {params.mode === 'expired' || 'Não se preocupe! Digite abaixo o e-mail ou nome de usuário cadastrado para receber as instruções de recuperação da senha.'}

        </Text>
        <FieldDiv>
          <Input
            name="user"
            label="Nome de usuário ou e-mail"
            placeholder="Digite seu login"
            value={user}
            onChange={(e) => onChangeUser(e.target.value)}
            error={errorUsernameNull && requested ? 'Este campo é obrigatório' : undefined}
            style={{ width: '97%', padding: '14px 0px' }}
          />
        </FieldDiv>

        <Button kind="primary" onClick={handleRecuperarSenha} loading={loading} style={{ width: '97%', display: 'flex', justifyContent: 'center' }}>
          Recuperar Senha
        </Button>

        <Line src={line} alt="Line" />

        <Text type="bold" style={{ color: '#515151', paddingTop: '2%' }}>Não lembra seu nome de usuário ou e-mail?</Text>

        <Text style={{ color: '#515151' }}>
          Entre em contato com a sua escola para recuperar seu acesso.
        </Text>

      </MainDiv>
    </>
  );
}

export default ForgotPasswordForm;
