import amplitude from 'amplitude-js';
import { EventoAmplitude } from './enums';

export interface PropriedadesComuns {
  plataforma: 'Web';
  ambiente: string;
  tela: string;
  parceiro: string;
  app: 'login';
}

export const logEvent = <T extends PropriedadesComuns>(event: EventoAmplitude, payload: T) => {
  amplitude.getInstance().logEvent(event, payload);
};

export const initAmplitude = async (token: string) => {
  amplitude.getInstance().init(token);
};
