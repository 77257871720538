import React from 'react';
import { ThemeProvider, defaultTheme } from '@arcotech-services/eem-afrodite-ui';
import { initAmplitude } from 'amplitude/amplitude.helper';
import Routes, { Router } from './Routes';
import { LogoProductContext, useContextValue } from './Context';

initAmplitude(`${process.env.REACT_APP_AMPLITUDE_TOKEN}`);

function App() {
  const contextValue = useContextValue();

  return (
    <LogoProductContext.Provider value={contextValue}>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </LogoProductContext.Provider>
  );
}

export default App;
