import styled from 'styled-components';
import logo from 'assets/logo.png';

// image with max height, but preserve aspect ratio
const LogoImg = styled.img`
  max-height: 92px;
  width: fit-content;
  max-width: 100%;
  border-radius: 0.5rem;
`;

type LogoType= {
  src: string | undefined,
}
function Logo({ src }: LogoType) {
  return (
    <LogoImg src={src || logo} alt="Logo" />
  );
}
export default Logo;
