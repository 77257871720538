import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {
  Input,
  Button,
  Typography,
  Toast,
} from '@arcotech-services/eem-afrodite-ui';
import { useLogEvent, useQuery } from 'hooks';
import { checkPin, resetPassword } from 'services/api';
import { useHistory } from 'react-router-dom';
import Validation from 'services/validations';
import { WarnPage, Logo } from 'components';
import { LogoProductContext } from 'Context';
import { EventoAmplitude } from 'amplitude/enums';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import { RulesCheck } from './components';
import { ruleIcon } from './components/RulesCheck/RulesCheck';

const MIN_LENGTH_PASSWORD = 5;

const ContainerDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
`;

const ContentDiv = styled.div`
  width: 23rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const MainDiv = styled.div`
  width: 97%;
  padding: 5px 5px;
  text-align: left;
`;

const FieldDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
`;

const RulesDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem;
`;

function HomePage() {
  const { logoUrl, productParceiro } = useContext(LogoProductContext);
  const [showPassword, setShowPassword] = useState('password');
  const [password, setPassword] = useState('');
  const [passwordVerified, setPasswordVerified] = useState('');
  const [textShowPassBtn, setTextShowPassBtn] = useState('mostrar');
  const [pin, setPin] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [errorOnResetPassword, setErrorOnResetPassword] = useState(false);
  const [tipoErro, setTipoErro] = useState<'old_password' | null>(null);
  const [errorAfterCLick, setErrorAfterClick] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [passwordRules, setPasswordRules] = useState<ruleIcon[]>([
    'check',
    'check',
  ]);

  const query = useQuery();
  const history = useHistory();
  const logEvent = useLogEvent();

  const validation = new Validation();

  const { Title, Text } = Typography;

  const errorPassword1Empty = !password;
  const errorPassword2Empty = !passwordVerified;
  const errorPasswordsDontMatch = password !== passwordVerified;

  const verifyPin = async (pinCaught: string | null) => {
    if (pinCaught) {
      const result = await checkPin(pinCaught);
      if (result.ok) {
        setPin(pinCaught);
      } else {
        history.push('/forgot/expired');
      }
    }
  };
  useLayoutEffect(() => {
    const paramPin = query.get('pin');
    verifyPin(paramPin);
  }, []);

  useEffect(() => {
    logEvent(EventoAmplitude.VISUALIZOU_TELA, { tela: 'cadastrar nova senha', parceiro: productParceiro });
  }, []);

  const handleMostrarClick = () => {
    setShowPassword(showPassword === 'password' ? 'text' : 'password');
    setTextShowPassBtn(textShowPassBtn === 'mostrar' ? 'ocultar' : 'mostrar');
  };

  let timeout: NodeJS.Timeout;

  const verifyPasswordRules = (passwordInTest: string) => {
    let tempRules = passwordRules;
    if (validation.minLength(passwordInTest, MIN_LENGTH_PASSWORD)) {
      tempRules = ['valid', ...tempRules.slice(1)];
    } else {
      tempRules = ['error', ...tempRules.slice(1)];
    }

    if (
      validation.hasLetters(passwordInTest)
      && validation.hasNumbersAndSpecialCharacters(passwordInTest)
    ) {
      tempRules = [...tempRules.slice(0, 1), 'valid'];
    } else {
      tempRules = [...tempRules.slice(0, 1), 'error'];
    }

    timeout = setTimeout(() => {
      setPasswordRules(tempRules);
    }, 1000);
  };

  useEffect(() => clearTimeout(timeout), []);

  const onChangePassword = (value: string) => {
    setPassword(value);
    setErrorAfterClick(false);
    verifyPasswordRules(value);
    setErrorOnResetPassword(false);
  };
  const onChangePasswordVerified = (value: string) => {
    setPasswordVerified(value);
    setErrorAfterClick(false);
    setErrorOnResetPassword(false);
  };
  const renderWarn = useMemo(
    () => pin !== '' && !resetPasswordSuccess,
    [pin, resetPasswordSuccess],
  );

  const registerNewPassword = useCallback(async () => {
    setLoading(true);

    if (errorPasswordsDontMatch || errorPassword1Empty || errorPassword2Empty) {
      setErrorAfterClick(true);
      setLoading(false);
      return;
    }

    if (pin && !errorAfterCLick) {
      const result = await resetPassword(pin, passwordVerified);
      if (result.ok) {
        logEvent(
          EventoAmplitude.CADASTROU_OUTRA_SENHA,
          {
            tela: 'Esqueceu a senha',
            parceiro: productParceiro,
          },
        );
        setResetPasswordSuccess(true);
      } else {
        setErrorOnResetPassword(true);

        if (result.data === 'OLD_PASSWORD') {
          setTipoErro('old_password');
        } else {
          setTipoErro(null);
        }
      }
      setLoading(false);
    }
  }, [
    pin,
    passwordVerified,
    errorPasswordsDontMatch,
    errorPassword1Empty,
    errorPassword2Empty,
    errorAfterCLick,
  ]);

  const returnConfirmPasswordError = () => {
    if (errorPassword2Empty && errorAfterCLick) {
      return 'Este campo é obrigatório';
    }
    if (errorPasswordsDontMatch && errorAfterCLick) {
      return 'As senhas não são iguais, tente novamente.';
    }

    return undefined;
  };

  return (
    <>
      {!renderWarn && !resetPasswordSuccess && (
        <LoadingOverlay />
      )}
      {renderWarn && (
        <ContainerDiv id="container-div">
          <ContentDiv>
            <Logo src={logoUrl} />
            <MainDiv>
              <Title
                level={2}
                style={{ color: '#333333', marginBottom: '10px' }}
              >
                Cadastre sua nova senha
              </Title>
              {errorOnResetPassword && (
                (tipoErro === 'old_password')
                  ? (
                    <Toast
                      id={1}
                      kind="error"
                      title="Tente novamente com uma senha que você não tenha usado antes"
                      closeButton={false}
                      style={{ width: 'auto' }}
                    />
                  )
                  : (
                    <Toast
                      id={1}
                      kind="error"
                      title="Falha na alteração de senha"
                      closeButton={false}
                      style={{ width: 'auto' }}
                    />
                  )
              )}
              <FieldDiv>
                <Input
                  label="Nova senha"
                  type={showPassword}
                  placeholder="Digite sua senha"
                  value={password}
                  data-cy="senha"
                  onChange={(e) => onChangePassword(e.target.value)}
                  style={{ width: '100%', padding: '10px 0px' }}
                  error={
                    errorPassword1Empty && errorAfterCLick
                      ? 'Este campo é obrigatório'
                      : undefined
                  }
                />
              </FieldDiv>

              <FieldDiv>
                <Input
                  label="Confirmar nova senha"
                  type={showPassword}
                  placeholder="Digite sua senha"
                  value={passwordVerified}
                  data-cy="confirma-senha"
                  onChange={(e) => onChangePasswordVerified(e.target.value)}
                  style={{ width: '100%', padding: '10px 0px' }}
                  error={returnConfirmPasswordError()}
                />
              </FieldDiv>

              <CheckboxDiv>
                <input
                  name="checkbox"
                  type="checkbox"
                  onChange={() => handleMostrarClick()}
                  placeholder="123"
                  style={{
                    width: '16px',
                    height: '16px',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}
                />
                <Text style={{ fontWeight: 500, fontSize: '14px !important' }}>
                  Mostrar senha
                </Text>
              </CheckboxDiv>

              <RulesDiv>
                <RulesCheck rulesError={passwordRules} />
              </RulesDiv>

              <Button
                kind="primary"
                onClick={registerNewPassword}
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                loading={loading}
                disabled={
                  passwordRules.includes('error')
                  || passwordRules.includes('check')
                }
              >
                Cadastrar nova senha
              </Button>
            </MainDiv>
          </ContentDiv>
        </ContainerDiv>
      )}
      {resetPasswordSuccess && (
        <ContainerDiv id="container-div">
          <ContentDiv>
            <WarnPage
              title="Nova senha cadastrada com sucesso!"
              text="Sua nova senha foi cadastrada com sucesso, agora você já pode acessar sua conta."
              showButton
              showBottom={false}
            />
          </ContentDiv>
        </ContainerDiv>
      )}
    </>
  );
}

export default HomePage;
