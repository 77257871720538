import styled from 'styled-components';
import { Typography } from '@arcotech-services/eem-afrodite-ui';

const MainDiv = styled.div`
  width: 97%;
  padding: 5px 5px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
`;

const Info = styled.div`
  width: 97%;
  font-size: 16px;
  color: #515151;
  line-height: 24px;
`;

const ContainerDiv = styled.div`
  width: 23rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
function AmbienteIndisponivel() {
  const { Text, Title } = Typography;
  return (
    <>
      <MainDiv>
        <ContainerDiv id="container-div">
          <ContentDiv>
            <Title
              level={2}
              style={{
                color: '#333333', marginBottom: '10px', marginTop: '25px', lineHeight: '1.6em',
              }}
            >
              Ambiente Indisponível
            </Title>
            <Info>
              <Text>
                Para mais informações, entre em contato com o administrador do sistema
              </Text>
            </Info>
          </ContentDiv>
        </ContainerDiv>
      </MainDiv>
    </>
  );
}
export default AmbienteIndisponivel;
