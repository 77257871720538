import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  CheckCircleIcon,
  AlertCircleIcon,
} from '@arcotech-services/eem-icons';

const ICON_SIZE_DEFAULT = 15;

interface RulesCheckProps {
  rulesError: ruleIcon[];
}

export type ruleIcon = 'check' | 'error' | 'valid' | null;

const RuleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const RulesDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RuleText = ({ children, color }: { children: ReactNode, color: string }) => (
  <span style={{ marginLeft: '0.5rem', fontSize: '0.8rem', color }}>{ children }</span>
);

function RulesCheck({ rulesError }: RulesCheckProps) {
  const rulesText = ['Mínimo 5 caracteres', 'Combinação de letras, números e símbolos'];

  const renderRuleContent = (rule: ruleIcon, index: number) => {
    switch (rule) {
      default:
      case 'check':
        return (
          <>
            <CheckCircleIcon size={ICON_SIZE_DEFAULT} color="#767676" />
            <RuleText color="#515151">{ rulesText[index] }</RuleText>
          </>
        );
      case 'error':
        return (
          <>
            <AlertCircleIcon size={ICON_SIZE_DEFAULT} color="#DA1414" />
            <RuleText color="#DA1414">{ rulesText[index] }</RuleText>
          </>
        );
      case 'valid':
        return (
          <>
            <CheckCircleIcon size={ICON_SIZE_DEFAULT} color="#287D3C" />
            <RuleText color="#515151">{ rulesText[index] }</RuleText>
          </>
        );
    }
  };

  return (
    <RulesDiv>
      { rulesError.map((rule, index) => (
        <RuleDiv key={index.toString()}>
          { renderRuleContent(rule, index) }
        </RuleDiv>
      )) }
    </RulesDiv>
  );
}

export default RulesCheck;
