import React from 'react';
import styled from 'styled-components';

import loadingGif from 'assets/loading.gif';

export interface LoadingOverlayProps {
    title?: string;
}

const LoadingImage = styled.img`
  width: 140px;
  align-self: center;
`;

const ContainerDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
`;

export function LoadingOverlay({ title }: LoadingOverlayProps) {
  return (
    <ContainerDiv>
      <ContentDiv>
        {title && <Title>{title}</Title>}
        <LoadingImage src={loadingGif} alt="Carregando..." />
      </ContentDiv>
    </ContainerDiv>
  );
}
