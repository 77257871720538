import { EventoAmplitude } from 'amplitude/enums';
import { LogoProductContext } from 'Context';
import { useLogEvent } from 'hooks';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ForgotPasswordForm } from './components';

const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;

  @media(max-width: 760px) {
    #container-div {
      width: 320px;
      align-items: baseline;
    }
  }
`;

const ContainerDiv = styled.div`
  width: 23rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

function ForgotPasswordPage() {
  const {
    productParceiro,
  } = useContext(LogoProductContext);

  const logEvent = useLogEvent();

  useEffect(() => {
    logEvent(EventoAmplitude.VISUALIZOU_TELA, { tela: 'esqueceu a senha', parceiro: productParceiro });
  }, []);

  return (
    <MainDiv>

      <ContainerDiv id="container-div">
        <ContentDiv>

          <ForgotPasswordForm />
        </ContentDiv>

      </ContainerDiv>

    </MainDiv>
  );
}

export default ForgotPasswordPage;
