export default class Validation {
    username = (username: string) => {
      if (username.length === 0) return true;
      return false;
    };

    password = (password: string) => {
      if (password.length === 0) return true;
      return false;
    };

    passwordsAreEqual = (password: string, passwordCheck: string) => {
      if (password === passwordCheck) {
        return true;
      } return false;
    };

    minLength = (value: string, minLength: number) => {
      if (value.length >= minLength) {
        return true;
      } return false;
    };

    // rule 2 - password must have letters and numbers or special characters
    hasLetters = (value: string) => {
      if (value.match(/[a-zA-Z]/)) {
        return true;
      } return false;
    };

    hasNumbers = (value: string) => {
      if (value.match(/[0-9]/)) {
        return true;
      } return false;
    };

    hasSpecialCharacters = (value: string) => {
      if (value.match(/[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/)) {
        return true;
      } return false;
    };

    hasNumbersAndSpecialCharacters = (value: string) => {
      if (this.hasNumbers(value) && this.hasSpecialCharacters(value)) {
        return true;
      } return false;
    }
}
