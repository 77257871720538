import {
  createContext, useContext, useState,
} from 'react';

export interface LogoContextData {
    logoUrl: string;
    productParceiro: string;
    context: string;
    changeUrlLogo: (urlLogo: string) => void;
    changeProduct: (product: string) => void;
    changeContext: (product: string) => void;
}

export const logoUrlContextDefaultValue: LogoContextData = {
  logoUrl: 'logo',
  productParceiro: 'ESCOLA_APP',
  context: 'context.com',
  changeUrlLogo: () => null,
  changeProduct: () => null,
  changeContext: () => null,
};
export function useContextValue(): LogoContextData {
  const [logoUrl, setLogo] = useState<string>('');
  const [productParceiro, setProductParceiro] = useState<string>('');
  const [context, setContexto] = useState<string>('');

  const changeUrlLogo = (urlLogo: string) => {
    setLogo(urlLogo);
  };

  const changeProduct = (product: string) => {
    setProductParceiro(product);
  };

  const changeContext = (contexto: string) => {
    setContexto(contexto);
  };

  return {
    logoUrl,
    productParceiro,
    context,
    changeUrlLogo,
    changeProduct,
    changeContext,
  };
}

export const LogoProductContext = createContext<LogoContextData>(logoUrlContextDefaultValue);
export const useLogo = () => useContext(LogoProductContext);
