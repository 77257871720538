/* eslint-disable max-len */
import {
  useContext, useLayoutEffect, useState,
} from 'react';
import styled from 'styled-components';
import { authenticate } from 'services/api';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Typography,
  Toast,
} from '@arcotech-services/eem-afrodite-ui';
import Validation from 'services/validations';
import { EventoAmplitude } from 'amplitude/enums';
import { useLogEvent, useQuery } from 'hooks';
import { LogoProductContext } from 'Context';
import { isEmail } from 'utils/utils';

export enum ParamMNumber {
  AccessOutOfScheduledHour = '555',
}

const MainDiv = styled.div`
  padding: 5px 0;
  text-align: left;
  width: 100%;
`;

const FieldDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Mostrar = styled.span`
  font-size: 13px;
  color: #0f3670;
  font-weight: 600;
  cursor: pointer;
`;

function LoginForm() {
  const logEvent = useLogEvent();
  const { productParceiro } = useContext(LogoProductContext);
  const history = useHistory();
  const query = useQuery();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorAccessOutOfHour, setErrorAccessOutOfHour] = useState(false);
  const [loginFailed, setloginFailed] = useState(false);
  const [loginFailedInactivatedUser, setloginFailedInactivatedUser] = useState(false);
  const { Title } = Typography;

  const [showPassword, setShowPassword] = useState('password');
  const [textShowPassBtn, setTextShowPassBtn] = useState('mostrar');

  function verifyIsAccessOutOfScheduledHour(paramM: string) {
    if (paramM === ParamMNumber.AccessOutOfScheduledHour) {
      return true;
    }
    return false;
  }

  function handleParamM(paramM: string | null) {
    if (paramM && verifyIsAccessOutOfScheduledHour(paramM)) {
      setErrorAccessOutOfHour(true);
      logEvent(EventoAmplitude.PREENCHEU_LOGIN_FORA_DO_HORARIO_PERMITIDO, {
        tela: 'login',
        parceiro: productParceiro,
      });
    } else {
      setErrorAccessOutOfHour(false);
    }
  }

  useLayoutEffect(() => {
    const paramM = query.get('m');
    handleParamM(paramM);
  }, []);

  const validation = new Validation();
  const errorPasswordNull = validation.password(password) || !password;
  const errorUsernameNull = validation.username(user) || !user;

  function onChangeUser(value: string) {
    setUser(value);
    setloginFailed(false);
    setloginFailedInactivatedUser(false);
  }

  const onChangePassword = (value: string) => {
    setPassword(value);
    setloginFailed(false);
    setloginFailedInactivatedUser(false);
  };

  const getContext = () => {
    // TODO: remover context para redirecionar pra home
    let context = window.location.host;
    // TODO: remover esse replace qndo for pra home real
    context = context.replace('.login', '');
    return context;
  };

  const onHandleAuthenticateReturn = (result: any) => {
    const context = getContext();

    if (result.ok) {
      logEvent(EventoAmplitude.REALIZOU_LOGIN, {
        tela: 'login',
        parceiro: productParceiro,
        username: isEmail(user),
      });
      setloginFailed(false);
      setloginFailedInactivatedUser(false);
      // TODO: enviar para a Home real
      const accessToken = result.data?.access_token;
      const homePath = `https://${context}/linkkeycloak/redirecttourl?jwt=${accessToken}`;
      history.push('/carregando-ambiente');
      window.location.href = homePath;
      return;
    }

    logEvent(EventoAmplitude.PREENCHEU_LOGIN_SENHA_INVALIDOS, {
      tela: 'login',
      parceiro: productParceiro,
    });

    if (result.data?.title?.toString() === 'Unauthorized') {
      setloginFailed(true);
    } else if (result.data?.toString() === 'Invalid user') {
      setloginFailed(true);
    } else if (result.data?.toString() === 'Inactivated user') {
      setloginFailedInactivatedUser(true);
      logEvent(EventoAmplitude.PREENCHEU_LOGIN_USUARIO_INATIVO, {
        tela: 'login',
        parceiro: productParceiro,
      });
    } else {
      setloginFailed(true);
    }
  };

  const handleEsqueciSenha = () => {
    logEvent(EventoAmplitude.CLICOU_ESQUECI_SENHA, {
      tela: 'login',
      parceiro: productParceiro,
    });
    history.push('/forgot');
  };

  const handleClick = async () => {
    logEvent(EventoAmplitude.CLICOU_ENTRAR, {
      tela: 'login',
      parceiro: productParceiro,
    });
    if (!user && !password) {
      setloginFailed(true);
      return;
    }
    if (!password) { setloginFailed(true); return; }
    if (!user) { setloginFailed(true); return; }

    setLoadingButton(true);
    const context = getContext();
    const result = await authenticate(user, password, context);
    if (result) {
      onHandleAuthenticateReturn(result);
      setLoadingButton(false);
    }
  };

  const handleMostrarClick = () => {
    setShowPassword(showPassword === 'password' ? 'text' : 'password');
    setTextShowPassBtn(textShowPassBtn === 'mostrar' ? 'ocultar' : 'mostrar');
  };

  return (
    <MainDiv>
      <Title level={2} style={{ color: '#333333' }}>
        Acesse sua conta
      </Title>
      {errorAccessOutOfHour && (!loginFailed && !loginFailedInactivatedUser) && (
        <Toast
          kind="error"
          title="Você está fora do horário permitido"
          description="Para saber mais, contate o administrador do sistema"
          closeButton
          onClose={() => setErrorAccessOutOfHour(false)}
          style={{ width: 'auto' }}
        />
      )}
      {loginFailed && (!errorUsernameNull && !errorPasswordNull) && (
        <Toast
          kind="error"
          title="Usuário ou senha inválidos"
          closeButton={false}
          style={{ width: 'auto' }}
        />
      )}
      {loginFailedInactivatedUser && (!errorUsernameNull && !errorPasswordNull) && (
        <Toast
          kind="info"
          title="Cadastro inativo. Entre em contato com a escola."
          closeButton={false}
          style={{ width: 'auto' }}
        />
      )}

      <FieldDiv>
        <Input
          label="Nome de usuário ou e-mail"
          value={user}
          placeholder="Digite seu login"
          onChange={(e) => onChangeUser(e.target.value)}
          error={
            errorUsernameNull && loginFailed
              ? 'Por favor, digite seu usuário'
              : undefined
          }
          style={{ width: '100%', display: 'block', padding: '16px 0px' }}
        />
      </FieldDiv>

      <FieldDiv>
        <Input
          label="Senha"
          type={showPassword}
          placeholder="Digite sua senha"
          value={password}
          onChange={(e) => onChangePassword(e.target.value)}
          error={
            errorPasswordNull && loginFailed
              ? 'Por favor, digite sua senha de acesso'
              : undefined
          }
          style={{ width: '100%', display: 'block' }}
          icon={
            <Mostrar onClick={handleMostrarClick}>{textShowPassBtn}</Mostrar>
          }
        />
      </FieldDiv>
      <Button
        style={{ margin: '16px 0', padding: '0', textDecoration: 'none' }}
        kind="link"
        onClick={handleEsqueciSenha}
      >
        Esqueci minha senha
      </Button>
      <Button
        kind="primary"
        loading={loadingButton}
        onClick={handleClick}
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <span style={{ padding: '0px 6px', fontSize: '14px' }}>Entrar</span>
      </Button>
    </MainDiv>
  );
}

export default LoginForm;
