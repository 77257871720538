import styled from 'styled-components';
import leftArrow from 'assets/left-arrow.png';
import { useHistory } from 'react-router-dom';

const ArrowDiv = styled.div`
  height: 60px;
`;
const LeftArrow = styled.img`
  width: 100%;
  cursor: pointer;
`;
function Arrow() {
  const history = useHistory();
  return (
    <ArrowDiv onClick={() => history.push('/')}>
      <LeftArrow src={leftArrow} alt="Arrow" />
    </ArrowDiv>
  );
}
export default Arrow;
