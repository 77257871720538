import styled from 'styled-components';
import line from 'assets/line.png';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@arcotech-services/eem-afrodite-ui';
import { ArrowDiv, Logo } from 'components';
import { useContext } from 'react';
import { LogoProductContext } from 'Context';
import { EventoAmplitude } from 'amplitude/enums';
import { useLogEvent } from 'hooks';

const MainDiv = styled.div`
  width: 97%;
  padding: 5px 5px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
`;

const Line = styled.img`
  width: 97%;
  padding: 24px 0px 12px;
`;
const Info = styled.div`
  width: 97%;
  font-size: 16px;
  color: #515151;
  line-height: 24px;
`;

const ContainerDiv = styled.div`
  width: 23rem;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
type WarnType= {
  title: string | undefined,
  text: string | undefined,
  showButton: boolean,
  showBottom: boolean,
}
function Warn({
  title, text, showButton, showBottom,
} : WarnType) {
  const { logoUrl, productParceiro } = useContext(LogoProductContext);

  const history = useHistory();
  const logEvent = useLogEvent();

  const { Text, Title } = Typography;

  const handleClickVoltarLogin = () => {
    logEvent(EventoAmplitude.CLICOU_VOLTAR_PARA_LOGIN, { tela: 'Voltar para login', parceiro: productParceiro });
    history.push('/');
  };

  return (
    <>
      <MainDiv>
        <ContainerDiv id="container-div">
          <ContentDiv>

            <ArrowDiv />
            <Logo src={logoUrl} />
            <Title
              level={2}
              style={{
                color: '#333333', marginBottom: '10px', marginTop: '25px', lineHeight: '1.6em',
              }}
            >
              {title}
            </Title>
            <Info>
              <Text>
                {text}
              </Text>
            </Info>
            { showButton && (
              <>
                <Button kind="primary" onClick={() => handleClickVoltarLogin()} style={{ width: '97%', display: 'block', marginTop: '30px' }}>Voltar para tela de Login</Button>
              </>
            )}

            { showBottom && (
            <>
              <Line src={line} alt="Line" />

              <Text type="bold" style={{ color: '#515151', paddingTop: '2%' }}>Não possui cadastro na plataforma?</Text>

              <Text style={{ color: '#515151' }}>
                Entre em contato com a sua escola para solicitar seu cadastro.
              </Text>
            </>
            )}
          </ContentDiv>

        </ContainerDiv>
      </MainDiv>
    </>
  );
}
export default Warn;
