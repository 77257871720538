import React, { useCallback, useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useGetDomain } from 'hooks';
import { getInfoContext } from 'services/api';
import { LogoProductContext } from 'Context';
import { IndisponivelPage } from 'components';
import {
  LoginPage, ForgotPasswordPage, RegisterNewPasswordPage, AfterLoginLoadingPage,
} from './pages';

const DEFAULT_ROUTE_INDISPONIVEL = '/indisponivel';

function Routes() {
  const {
    changeContext,
    changeProduct,
    changeUrlLogo,
  } = useContext(LogoProductContext);
  const domainName = useGetDomain();
  const history = useHistory();

  const changeFavIcon = () => {
    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    if (favicon?.href) {
      favicon.href = `https://du72ei5gcsbn3.cloudfront.net/comum/${domainName}/favicon.ico`;
    }
  };

  function changeLogo(logoUrl: string | null | undefined) {
    if (logoUrl) changeUrlLogo(logoUrl);
    else changeUrlLogo(`https://du72ei5gcsbn3.cloudfront.net/comum/${domainName}/logo.png`);
  }

  const callGetInfoByContext = useCallback(async () => {
    const result = await getInfoContext(window.location.hostname);

    if (result.ok) {
      changeLogo(result.data?.logo);
      document.title = result.data?.nameContext;
      changeContext(result.data?.context);
      changeFavIcon();
      changeProduct(result.data?.product);
    } else {
      history.push(DEFAULT_ROUTE_INDISPONIVEL);
    }
  }, [changeUrlLogo]);

  useEffect(() => {
    if (history.location.pathname === DEFAULT_ROUTE_INDISPONIVEL) return;
    callGetInfoByContext();
  }, []);

  return (
    <Switch>
      <Route path="/" render={(props) => <LoginPage {...props} reLoad />} exact />
      <Route path="/forgot/:mode" component={ForgotPasswordPage} />
      <Route path="/forgot" component={ForgotPasswordPage} />
      <Route path="/register-new-password" component={RegisterNewPasswordPage} />
      <Route path={DEFAULT_ROUTE_INDISPONIVEL} component={IndisponivelPage} />
      <Route path="/carregando-ambiente" component={AfterLoginLoadingPage} />
      <Redirect to="/" />
    </Switch>
  );
}

export const Router = BrowserRouter;
export default Routes;
